.root {
  width: max-content;
}

.content {
  display: flex;
  gap: 36px;
  align-items: center;
  color: var(--color-white);

  svg {
    width: 24px;
    height: 24px;
  }
}
