@import "../../../../ui/media.scss";

.beneficial-owner-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 4px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--color-white);
  border: 1px solid var(--color-progress-border);
  position: relative;
  transition: background-color 150ms ease;

  &--disabled {
    opacity: 0.6;
    border-style: dashed;
    border-color: var(--color-slate);
    cursor: not-allowed;
  }

  @include tablet {
    padding: 0 8px 0 4px;
  }

  .short-info-contact {
    color: var(--color-ghost);
  }

  &-actions {
    display: flex;
  }

  .owner-row-short-info {
    flex: 1 0 130px;
    line-height: 1.2;
    min-width: 0;
    padding: 0 8px;

    @include landscape {
      padding: 0 14px;
      flex-basis: 180px;
    }

    li {
      font-weight: 500;
      @include truncate;
      width: 100%;
      display: block;
      min-width: 0;
    }
  }

  .outreach-status-chip {
    display: flex;
    align-items: center;
    flex-basis: 175px;
    justify-content: flex-end;

    font-weight: 500;
    color: var(--color-link);
    fill: var(--color-link);

    @include tablet {
      justify-content: flex-start;
      margin-right: 12px;
    }

    svg {
      margin-right: 6px;
    }

    span {
      display: none;
      @include tablet {
        display: inline-block;
      }
    }
  }

  .benefical-owner-item-actions {
    display: none;
    @include landscape {
      display: block;
    }
  }

  .outeach-status-chip--not-sent {
    color: var(--color-red-heavy);
    fill: var(--color-red-heavy);
  }
}
