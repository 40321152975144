@import "../../../../ui/media.scss";

.beneficial-owners {
  .beneficial-dropdown {
    padding: 0 0 10px 0;
  }

  .status-boxes {
    padding: 0 0 5px 0;
  }

  .send-to-outreach-container {
    max-width: $tablet;
    margin: 24px auto;

    .send-to-outreach-text {
      font-weight: 500;
      color: var(--color-text-passive);
    }

    .send-to-outreach-ul {
      .send-to-outreach-li {
        background-color: var(--color-white);
        border: 1px solid var(--color-link);
        // color: var(--color-link);
        border-radius: 20px;
        padding: 4px 12px;
        margin-right: 8px;
        display: inline-block;
        line-height: normal;
      }
    }
  }
}
