.pagination {
  display: inline-flex;
  border-radius: 5px;

  button {
    border: 1px solid var(--color-link);
    border-right: 0;
    width: 32px;
    height: 32px;
    background-color: var(--color-bg);

    &.is-active,
    &:hover,
    &:focus,
    &:active {
      background-color: var(--link-alt);
    }
  }

  li {
    &:last-child {
      button {
        border-right: 1px solid var(--color-link);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &:first-child {
      button {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
}
