@import "../../ui/media.scss";

.login-page {
  max-width: 100vw;
  overflow: hidden;

  article > div {
    padding: 60px 0;
    display: block;
    margin: 0 auto;
    max-width: $landscape;
  }

  header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .login-top h1 {
    text-align: center;
    font-size: 2.2rem;

    @include tablet {
      font-size: 4rem;
      margin-top: 40px;
    }
  }

  .logo {
    max-width: 125px;
    width: 100%;

    @include tablet {
      max-width: 200px;
    }

    .image {
      background-color: transparent;
    }
  }

  form {
    padding: 0 0 30px 0;

    @include tablet {
      padding: 20px 0 30px 0;
    }
  }

  .input {
    margin: 0 0 10px 0;
    display: block;
  }

  .lang-selector {
    margin: 0 0 20px 0;
  }

  .bottom-box {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;

    .support-box {
      box-shadow: var(--color-button-shadow);
      border: 1px solid var(--color-link);
      width: 100%;

      @include tablet {
        width: auto;
        min-width: 400px;
      }
    }
  }
}
