.status-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;

  .status-box-message {
    line-height: 1.5;
    margin-left: 12px;
  }

  &.default-box {
    .status-box-message {
      padding: 7px 0 0 0;
    }
  }

  .icon {
    width: 30px;
    height: 30px;
    display: none;
  }

  &.error-box {
    background-color: rgba(246, 234, 236, 1);
    // box-shadow: 0 0 14px -6px #882323;
    border: 1px solid #88232375;

    .icon-error-outline {
      fill: #c04647;
      display: block;
    }
  }

  &.success-box {
    background-color: #e5f2f4;
    // box-shadow: 0 0 14px -6px #27624e;
    border: 1px solid #27624e75;

    .icon-checkmark {
      fill: var(--color-link);
      display: block;
    }
  }

  &.warning-box {
    background-color: rgba(255, 246, 213, 0.75);
    // box-shadow: 0 0 14px -6px #74510b80;
    border: 1px solid #74510b50;

    .icon-warning-outline {
      fill: #e69900;
      display: block;
    }
  }

  &.info-box {
    background-color: rgba(213, 227, 255, 0.75);
    // box-shadow: 0 0 14px -6px #0b4b74;
    border: 1px solid #0b4b74;

    .icon-info-outline {
      fill: #0b4b74;
      display: block;
    }
  }

  &.transparent {
    background-color: transparent;
    border: none;
    padding: 8px;
  }
}
