@import "../ui/media.scss";

.app {
  .page {
    min-height: 100vh;
  }

  .case-page-menu {
    .nav-link > a {
      justify-content: left;
      display: flex;
    }
    .nav-link .active {
      background-color: var(--color-button);
      box-shadow: var(--color-button-shadow);
      color: var(--color-white);

      .icon {
        fill: var(--color-white);
      }
    }
  }

  .stripped {
    width: auto;
    margin: 0;

    &.background {
      background-color: var(--color-progress-bar-bg);
    }
  }
}
