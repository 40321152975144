.security-mark-help-modal {
  .image-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    img {
      width: 100%;
    }
  } 
}