@import "../../ui/media.scss";

.vertical-menu {
  @include desktop {
    transform: translateX(0);
  }

  .logo-wrapper {
    width: 90%;
    display: flex;
    flex-direction: row;
    margin: 8px auto 50px auto;

    .logo {
      flex: 1;
    }

    .spinner-motion {
      margin-left: 8px;
    }
  }

  nav .menu ul {
    margin: 60px 0 0 0;

    .nav-link {
      margin: 14px 0 0 0;
    }

    .link {
      display: block;
    }

    .icon {
      margin: -2px 10px 0 0;
    }
  }

  .menu {
    margin: 0 0 0 -16px;

    .menu-link {
      padding: 0 0 0 16px;

      @include truncate;
    }

    @include landscape {
      margin: 0 0 0 -25px;

      .menu-link {
        padding: 0 0 0 25px;
      }
    }
  }
}

.menu-wrapper.is-open {
  .vertical-menu {
    transform: translateX(0);
  }
}

.app {
  &.has-vertical-menu {
    @include desktop {
      width: calc(100% - var(--vertical-menu-width-tablet));
      margin: 0 0 0 var(--vertical-menu-width-tablet);
    }
  }
}
