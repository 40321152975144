.flag-with-country {
  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
  }

  &.on-end {
    justify-content: flex-end;
  }
}
