.data-list {
  display: flex;

  &.--small {
    font-size: 0.9em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  dt {
    opacity: 0.85;
  }

  dd {
    font-weight: 600;
  }

  dt,
  dd {
    word-break: break-word;
  }

  &.--vertical {
    flex-direction: column;

    dt,
    dd {
      width: 100%;
      margin: 0;
    }

    dd {
      margin-bottom: 10px;
    }
  }

  &.--horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    dt,
    dd {
      width: 100%;
    }

    dd {
      width: auto;
    }

    &.--left {
      dd {
        text-align: left;
      }
    }
    &.--right {
      dd {
        text-align: right;
        margin-left: auto;
      }
    }
    &.--center {
      dd {
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
