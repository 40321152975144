@import "../../../ui/media.scss";

.review-owner-list-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  font-weight: 400;
  position: relative;
  margin: 0 0 40px 0;

  .owner-item-name {
    font-weight: 500;
    width: 100%;
    @include truncate;

    position: relative;
    padding: 0 0 0 30px;

    & > span {
      font-weight: 400;
    }
  }

  .owner-item-share {
    margin: -2px 0 8px 30px;

    .owner-share {
      margin: 0 0 0 6px;
    }
  }

  .review-buttons {
    margin: 14px 0 0 30px;
    display: flex;

    .link {
      margin: 0 10px 0 0;
      min-width: 140px;
    }
  }

  .reminder-popover {
    & > .button {
      min-width: 140px;
    }
  }

  .owner-item-flag {
    position: absolute;
    left: 0;
    top: -1px;
  }

  .relative {
    max-width: 100%;
  }

  &.active {
    .beacon {
      display: block;
    }

    .event-button {
      display: flex;
    }
  }

  .owner-item-type {
    font-style: italic;
  }

  .owner-item-reminder,
  .owner-item-share,
  .owner-item-confirmed,
  .owner-item-docs,
  .owner-item-signature {
    color: var(--color-slate);
  }

  .owner-item-reminder,
  .owner-item-confirmed,
  .owner-item-docs,
  .owner-item-signature {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.4;
    display: flex;
    align-items: center;
    margin: 4px 0 0 0;
  }

  .timeline-item-type {
    font-weight: 400;
    font-style: italic;
    margin: 0 0 0 6px;
    color: var(--color-ghost);
  }

  .additional-event-info {
    position: relative;
    max-width: 280px;
  }

  .aligned {
    margin-top: 1px;
    margin-right: 8px;
  }

  .event-button,
  a.as-button.event-button {
    font-size: 1.6rem;
    // position: absolute;
    // right: 0;
    // top: 30px;
    // z-index: 10;
    margin: 14px 0 0 30px;
    min-width: 140px;

    .icon {
      margin: 0 6px 0 -3px;
      width: 20px;
      height: 20px;
    }

    .icon-disabled {
      margin: 0 0 0 6px;
    }
  }

  .event-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 32px;

    .button {
      margin: 0;
    }

    .button:first-child {
      margin-bottom: 10px;
    }
  }

  .event-button {
    &.is-disabled {
      .icon-disabled {
        display: block;
        margin: 0 0 0 6px;
      }

      .icon-edit {
        display: none;
      }
    }
  }
}
