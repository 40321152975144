.card {
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;

    h5 {
      margin-bottom: 0 !important;
    }

    .header-badge {
      display: flex;
      font-size: 15px;
      padding: 1px 12px;
      background-color: var(--link-alt);
      border: 1px solid var(--link-hovered);
      border-radius: 40px;
      font-weight: 700;
    }
  }

  &.--border {
    background-color: var(--link-alt);
    border: 2px solid var(--color-link);

    dt {
      opacity: 1;
    }
  }

  &.--accent {
    background-color: var(--color-link);
    color: var(--color-white);

    .header-badge {
      background-color: var(--color-bg);
      border-color: transparent;
      color: var(--link-shadow);
      font-weight: 600;
    }

    dt {
      opacity: 1;
    }
  }
}
