.root {
  max-width: 768px;
  margin: 40px auto;

  background-color: var(--color-yellow-light);
  padding: 10px 20px;
  border-radius: 10px;
}

.title {
  font-weight: 700;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.subtitle {
  //   color: var(--color-text-passive);
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.6;
}

.text {
  font-size: 0.9em;
  line-height: 1.4;
}
