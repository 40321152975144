@import "../../ui/media.scss";

.fieldset {
  border: 1px solid var(--color-progress-border);
  border-radius: 5px;
  position: relative;
  padding: 26px 10px 16px 10px;

  @include landscape {
    padding: 36px 16px 26px 16px;
  }

  @include tablet {
    padding: 36px 25px 26px 25px;
  }

  .fieldset-toned-box,
  .fieldset-highlight-box {
    margin-left: -10px;
    margin-right: -10px;
    background-color: var(--color-white);
    border-top: 1px solid var(--color-progress-border);
    border-bottom: 1px solid var(--color-progress-border);
    padding: 14px 10px;

    @include landscape {
      margin-left: -16px;
      margin-right: -16px;
      padding: 20px 16px;
    }

    @include tablet {
      margin-left: -25px;
      margin-right: -25px;
      padding: 30px 25px;
    }
  }

  .fieldset-toned-box {
    background-color: var(--link-alt);

    hr {
      border-top: 1px solid var(--color-progress-border);
    }
  }

  & > .fieldset-header {
    position: absolute;
    top: -18px;
    font-weight: 300;
    background-color: var(--color-progress-bar-bg);
    padding: 0 10px;
    font-weight: 300;
    font-size: 2rem;

    @include tablet {
      top: -21px;
      font-size: 2.2rem;
    }
  }

  .background-color-bg {
    background-color: var(--color-bg);
  }

  .fieldset-inner > b {
    margin: 0 0 8px 0;
    display: block;
    font-weight: 600;
  }

  &.with-columns {
    .fieldset-inner {
      @include tablet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .column {
    @include tablet {
      flex: 0 0 calc(50% - 25px);
      max-width: calc(50% - 25px);
    }
  }

  .full {
    @include tablet {
      flex: 0 0 100%;
      margin: 0 0 10px 0;
      max-width: 100%;
    }
  }

  .divider {
    margin: 20px 0;
  }
}
