.root {
}

.inProgress {
  color: blue;
}

.discarded {
  color: var(--color-red-heavy);
}

.finalized {
  color: var(--color-link);
}
