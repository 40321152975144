@import "../../../ui/media.scss";

.filter-view {
  position: relative;

  .no-results {
    padding: 20px 0;
  }

  .filter-content-wrapper {
    scroll-behavior: smooth;

    .filter-table {
      width: 100%;
      max-width: $desktop-plus;
    }

    .filter-table-wrapper {
      overflow: auto;
      width: 100%;
      padding: 0;
      padding-bottom: 20px;
    }
  }

  .retry-pending,
  .retry-error {
    padding: 40px 0;
    text-align: center;
  }

  .filter-content-bottom-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .input-label-wrapper {
      display: none;
    }

    .bottom-filters {
      display: flex;
      gap: 16px;
      margin-top: 6px;

      .document-search-result {
        font-size: 0.9em;
        color: var(--color-text-passive);
        min-height: 28px;
      }
    }
  }
}
