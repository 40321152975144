.country-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  button {
    overflow: hidden;
    background-color: var(--color-white);
    border-radius: 4px;
    box-shadow: 0 0 8px -3px var(--color-slate);
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--color-text);
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--link-alt);
    }

    .flag {
      box-shadow: none;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: inline-block;
      min-width: 40px;
    }

    span {
      display: block;
      padding: 0 4px 0 8px;
      white-space: nowrap;
      font-size: 0.9em;
    }

    .close-icon {
      margin-right: 8px;
      color: var(--color-link);
    }

    .disabled-icon {
      margin-right: 8px;
      color: var(--color-text-passive);
    }
  }
}
