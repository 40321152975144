@import "../../../ui/media.scss";

$timeline-width: 400px;

.case-review-page {
  padding-top: 40px;
  min-height: 100vh;

  hr {
    border-color: var(--color-link);
  }

  .content {
    margin-top: 100px;
  }

  .as-button.link.event-button,
  .small.as-button.link {
    .icon {
      fill: var(--color-white);
      stroke: none;
      margin: 0 6px 0 -3px;
      width: 20px;
      height: 20px;
    }
  }

  .download-case-column {
  }

  .onboarding-status-span {
    margin-right: 8px;

    font-size: 0.9em;
    color: var(--color-text-passive);

    &:not(:first-of-type)::before {
      content: "•";
      margin-right: 8px;
    }
  }

  .onboarding-status-p {
    line-height: 1.3;
    font-size: 0.9em;
  }

  .onboarding-failed-doc-li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 0.9em;

    font-weight: 500;
    color: var(--color-text-error);
    // background-color: var(--color-bg);
    // border-radius: 5px;
    // padding: 5px;
    // border: 1px solid var(--color-text-error);

    svg {
      margin-right: 12px;
      fill: var(--color-text-error);
      width: 20px;
      height: 20px;
    }
  }
}
