@import "../../../../ui/media.scss";

.document-collection {
  & hr {
    border-color: var(--color-snow-heavy);
  }
}

.document-upload-active {
  background-color: var(--color-bg);
  border: 1px solid var(--color-snow-heavy);
}

.document-upload-inactive {
  background-color: var(--color-snow);
  border: 2px dashed var(--color-progress-border);
}

.document-upload {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 10px 20px 10px;
  margin-bottom: 20px;

  @include landscape {
    padding: 15px 15px 20px 15px;
  }

  .document-upload-header {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    margin-bottom: 20px;
  }

  .document-upload-body {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .beacon-column {
    flex-basis: 0;

    @include landscape {
      flex-basis: 60px;
    }

    .beacon {
      width: var(--height-interaction-small);
      height: var(--height-interaction-small);

      @include landscape {
        width: var(--height-interaction-tablet);
        height: var(--height-interaction-tablet);
      }
    }
  }

  .content-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    overflow: hidden;

    @include landscape {
      justify-content: start;
    }

    .text-wrapper {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      line-height: 1.5;
      padding: 0 20px;

      @include landscape {
        text-align: start;
        padding-left: 10px;
      }

      b {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .text-passive {
      font-weight: 500;
      font-size: 0.9em;
    }
  }

  .delete-column {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;

    @include landscape {
      flex-basis: 60px;
    }
  }
}
