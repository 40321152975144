@import "../../../ui/media.scss";

.translations-page {
  .translations-table-wrapper {
    overflow-x: auto;
    width: 100%;
  }

  .translations-table {
    min-width: 800px;
    margin: 40px 0 0 0;
  }
  .fieldset-header {
    background-color: #fff;
  }

  textarea {
    padding: 10px;
  }

  td,
  th {
    text-align: left;
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--color-progress-border);
    }
  }

  .translations-header-site {
    width: 95px;
  }

  .translations-header-translated,
  .translations-header-added {
    width: 115px;

    @include desktop {
      display: none;
    }

    @include wide {
      display: table-cell;
    }
  }

  .translations-header-translation,
  .translations-header-original {
    width: calc(50% - 194px);

    @include desktop {
      width: calc(50% - 79px);
    }

    @include wide {
      width: calc(50% - 194px);
    }
  }

  .translations-header-translated-by {
    width: 130px;
    max-width: 130px;
  }

  .translations-header-button {
    width: 40px;
    padding: 0;
    padding-top: 4px;
  }

  .file-actions {
    i {
      display: block;
      margin: 4px 0 0 0;
    }
  }
}
