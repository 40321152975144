.new-language-select {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;

  .flag {
    margin-right: 10px;
  }

  .input.input-dropdown {
    margin: 0;
    --height-interaction-tablet: 32px;
    --height-interaction: 30px;
    flex: 1;

    svg {
      top: 3px;
      right: 3px;
    }

    .input-messages,
    .input-label-wrapper {
      display: none;
    }
  }
}
