@import "../../../../ui/media.scss";

.beneficial-owner-form,
.beneficial-owner-contact-form {
  .tablet-columns {
    justify-content: flex-start;
    column-gap: 20px;

    @include tablet {
      & > .column,
      & > div {
        flex: 0 0 calc(50% - 10px);
      }
    }

    @include desktop {
      & > .column,
      & > div {
        flex: 0 0 calc(calc(100% / 3) - calc(40px / 3));
      }
    }

    &.tablet-columns-two {
      @include desktop {
        & > .column,
        & > div {
          flex: 0 0 calc(50% - 10px);
        }
      }
    }
  }

  .button-row {
    > :last-child {
      margin-top: 12px;
      @include tablet {
        margin-top: 0;
      }
    }
  }

  .placeholder-column {
    display: none;

    @include desktop {
      display: block;
    }
  }
}
