.address-search {
  .search-results {
    transition: opacity 300ms ease;
    opacity: 0;
    pointer-events: none;
    background-color: white;
    box-shadow: 0 0 40px -10px #19424e;
    overflow: hidden;
    background-image: linear-gradient(
      -250deg,
      #273444 30%,
      #19424e 50%,
      #3c4858
    );
    border: 1px solid var(--color-input-border);
    border: 1px solid #273444;
    z-index: 10;

    &.populated-list {
      opacity: 1;
      pointer-events: all;
    }
  }

  li {
    border-bottom: 1px solid #273444;

    &:last-child {
      border-bottom: 0;
    }
  }

  button {
    width: 100%;
    padding: 12px 10px;
    color: var(--color-white);
    text-align: left;
    // background: linear-gradient(90deg, transparent 30%, transparent);
    // transition: background 300ms ease;

    // & > div {
    //   transition: transform 300ms ease;
    // }

    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(90deg, #0b5f89 30%, #2cb098);

      // & > div {
      //   transform: translateX(10px);
      // }
    }
  }

  .search-results {
    position: absolute;
    width: 100%;
    bottom: 100%;
    left: 0;
  }

  &.on-bottom {
    .search-results {
      bottom: auto;
      top: 100%;
    }
  }
}

.address-with-search.has-valid-address {
  .icon-checkmark {
    opacity: 1;
  }
}
