@import "../../../../ui/media.scss";

.doc-upload {
  flex-basis: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  column-gap: 25px;
  position: relative;

  .wl-reference-container {
    .input {
      margin: 0;
    }
  }

  .upload-button-container {
    .upload-button-span {
      width: 100%;
    }

    label {
      width: 100%;
    }

    .upload-file-input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  .progress {
    background-color: var(--color-bg);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity ease 300ms;
    pointer-events: none;
  }

  &.is-pending {
    .progress {
      opacity: 1;
    }
  }

  .view-link-container {
    font-weight: 500;
    line-height: 1.4;
    padding-bottom: 4px;
    height: auto;

    @include landscape {
      height: 28px;
    }

    .doc-upload-view-link {
      display: flex;
      align-items: center;
      height: 24px;
      line-height: 24px;
      padding: 0 10px 2px 10px;
    }

    .view-link-filename {
      margin-right: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .view-link-icon {
      display: flex;
      align-items: center;
    }

    .upload-file-too-large {
      color: var(--color-red-heavy);
      padding: 0 10px 2px 10px;
    }
  }

  .remove-button {
    margin-right: 12px;

    svg {
      margin: 0;
    }
  }
}
