@import "../../ui/media.scss";

#menu-wrapper-backdrop {
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-backdrop);
  background-color: var(--color-black);
}

.menu-wrapper {
  display: flex;
  max-height: 100vh;
  height: 100vh;
  width: 100%;

  &.is-open {
    #menu-wrapper-backdrop {
      opacity: 0.6;
      pointer-events: all;
    }
  }

  &.is-padded {
    .menu-wrapper-content {
      padding: 60px 0 200px;
    }
  }

  &-sidebar {
    max-width: 50%;

    @include tablet {
      max-width: calc(50% - 135px);
    }
  }

  &-sidebar:empty {
    display: none;
  }

  &-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-vertical-nav);
    pointer-events: none;

    @include desktop {
      position: relative;
    }

    > * {
      pointer-events: initial;
    }
  }

  &-button {
    pointer-events: all;
  }

  &-content {
    padding-bottom: 100px;
    overflow: auto;
    flex: 1;
  }
}

.menu-wrapper-button {
  display: block;
  position: fixed;
  bottom: 40px;
  right: 16px;
  z-index: 10;

  @include landscape {
    bottom: 50px;
    right: 25px;
  }

  @include desktop {
    display: none;
  }
}

.page {
  &.background .menu-wrapper-content {
    background-color: var(--color-progress-bar-bg);
  }
  &.centered .menu-wrapper-content {
    display: flex;
    justify-content: center;
    width: 100%;

    > * {
      max-width: $desktop;
    }
  }
}
