.root {
  position: absolute;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 900;
}

.dark {
  background-color: black;
  opacity: 0.5;
}

.blur {
  background-color: rgba(255, 255, 255, 0.7);
}
