@import "../../../ui/media.scss";

.modal {
  width: 100%;
  height: max-content;
  top: -45px;
  @include tablet {
    width: max-content;
    top: 0;
  }

  position: absolute;
  background-color: var(--color-bg);
  border-radius: 10px;

  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
}

.modalContent {
  position: relative;
  padding: 60px 20px 0px 20px;

  @include tablet {
    padding: 70px 40px 20px 40px;
  }

  .disclaimer {
    margin-bottom: 40px;
  }

  .warningBox {
    margin: 40px 0 10px 0;
  }

  .statusBox {
    margin-bottom: 10px;
  }

  .buttons {
    @include tablet {
      flex-direction: row;
    }

    display: flex;
    flex-wrap: wrap-reverse;
    gap: 14px;

    button {
      margin: 0;
    }

    button:nth-of-type(1) {
      flex: 1;
    }

    button:nth-of-type(2) {
      flex: 2;
    }
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  &:hover {
    background-color: var(--color-snow);
  }

  svg {
    width: 22px;
    height: 22px;
    color: var(--color-text);
  }

  @include tablet {
    width: 45px;
    height: 45px;

    svg {
      width: 24px;
      height: 24px;
      color: var(--color-text);
    }
  }
}
