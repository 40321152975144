@import "../../ui/media.scss";

.reminder-popover {
  position: relative;

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-dialog-backdrop);
  }

  &__container {
    position: fixed;
    bottom: -50px;
    left: 0;
    right: 0;
    z-index: var(--z-dialog);
    background-color: var(--color-white);
    box-shadow: var(--color-button-shadow);
    padding: 12px;
    padding-bottom: calc(50px + 12px);
    border-radius: 8px;

    @include tablet {
      position: absolute;
      top: unset;
      left: unset;
      right: -12px;
      top: -12px;
      bottom: unset;
      padding-bottom: 12px;
      min-width: 600px;
      width: 100%;
    }
  }

  .reminder-form {
    padding: 12px 12px 12px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    & > .button {
      min-width: 140px;
    }
  }
}
