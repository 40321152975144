.root {
  position: absolute;
}

.highlight {
  background-color: var(--color-yellow);
  color: var(--color-text);
  opacity: 1;
}

.chipContainer {
  position: relative;
  width: min-content;
  border-radius: 4px;

  .chip {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: unset;
    font-weight: 500;
    color: var(--color-link);
    fill: var(--color-link);
    cursor: pointer;

    padding-right: 9px;
    padding-left: 4px;

    svg {
      width: 20px;
      height: 20px;
    }

    .chipText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
  }

  .chipDisabled {
    opacity: 0.5;
    color: var(--color-text-passive);
    fill: var(--color-text-passive);
    cursor: default;
  }
}

.popover {
  min-width: 400px;
  position: absolute;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.2);

  max-height: 700px;
  overflow-y: auto;

  .title {
    padding: 10px 20px;
    font-weight: bold;
    border-bottom: 1px solid var(--color-snow-heavy);
  }

  .documents {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 20px;
    border-bottom: 1px solid var(--color-snow-heavy);
  }

  .documents:last-of-type {
    border-bottom: none;
  }

  .document {
    display: flex;
    gap: 20px;
    border-radius: 5px;

    .docIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
        color: var(--color-text-passive);
        fill: var(--color-text-passive);
        opacity: 0.8;
      }
    }

    .docContent {
      flex: 1;
      font-weight: 500;
    }

    .docTitle {
      line-height: normal;
    }

    .docInfo {
      display: flex;
      flex: 1;

      font-size: 0.9em;
    }

    .docLabelType,
    .docLabelRef {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .docLabelType {
      flex: 1;
    }

    .docLabelRef {
      flex: 1.25;
    }

    .label {
      color: var(--color-text-passive);
    }
  }
}
