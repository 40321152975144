@import "../../ui/media.scss";

.login-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  .circles {
    position: absolute;
    bottom: 0;
  }

  .circles:nth-child(1) {
    left: 0;
    transform: translate(-50%, 50%);
    height: calc(90vh - 20px);
    width: calc(90vh - 20px);

    @include tablet {
      height: 90vh;
      width: 90vh;
    }

    g {
      color: var(--color-link-hovered);
    }
  }

  .circles:nth-child(2) {
    right: 0;
    transform: translate(50%, 50%);
    height: calc(60vh - 20px);
    width: calc(60vh - 20px);

    @include tablet {
      height: 60vh;
      width: 60vh;
    }
  }
}
