@import "../../../ui/media.scss";

.dark-theme {
  .case-page {
    .case-requirements {
      color: var(--color-snow-heavy);
    }
  }
}

.case-ingress,
.case-claim {
  padding: 60px 0 0 0;
}

.case-page {
  article {
    max-width: $desktop;
  }

  .loader {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input {
    margin: 0 0 14px 0;
    display: block;
  }

  .case-edit {
    min-height: 100vh;
    padding-bottom: 200px;

    .main-case-content {
      &.is-disabled {
        opacity: 0.15;
        pointer-events: none;
      }
    }
  }

  .case-requirements {
    color: var(--color-slate);
    line-height: 1.5;
    padding: 20px 0 0 0;

    ul {
      list-style-type: disc;
      margin: 10px 0 20px 1em;
    }
  }

  .company-information {
    max-width: $tablet;
    margin: 0 auto;

    .card {
      margin: 0 10px;

      @include tablet {
        margin: 0 25px;
      }
    }
  }

  .fieldset {
    max-width: $tablet;
    margin: 0 auto;
  }

  .column {
    &.hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .primary-contact-top {
    margin: 0 0 40px 0;

    b {
      font-size: 2.2rem;
    }
  }

  .delete-warning {
    background-color: #f6eaec;
    color: var(--color-red-heavy);
    border: 1px solid var(--color-red-heavy);
    padding: 20px 16px;
    border-radius: 6px;
    margin: 10px 0 20px 0;
  }

  // .company-data {
  //   dl {
  //     display: flex;
  //     flex-wrap: wrap;
  //     margin: 0 0 30px 0;
  //   }

  //   dt,
  //   dd {
  //     margin: 0;
  //   }

  //   dt {
  //     flex: 0 0 200px;
  //   }

  //   dd {
  //     flex: 1 0 calc(100% - 200px);
  //   }
  // }
}

.delete-case,
.retract-case,
.send-for-confirmation,
.edit-case,
.manual-company-data {
  margin: 0 auto 20px auto;
  max-width: $tablet;
}

.delete-case,
.retract-case {
  margin: 40px auto;
}

.edit-case {
  margin-top: 40px;
  margin-bottom: 50px;
  .edit-case-button-text {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }

  .edit-case-message {
    // font-size: 0.9em;
    height: 28px;
    // color: var(--color-text-passive);
    line-height: 1.4;
  }

  .move-to-pending-review {
    min-height: 210px;
    margin-top: 40px;
    border-radius: 6px;

    color: #e69900;

    background-color: rgba(255, 246, 213, 0.75);
    padding: 20px 16px;
    border: 1px solid #74510b50;

    &--disabled {
      background-color: var(--color-snow-heavy);
      color: var(--color-text-passive);
      border-color: var(--color-text-passive);
      border-style: dashed;
    }
  }

  .move-to-pending-review-button {
    svg {
      margin-right: 10px;
    }
  }
}

.edit-case-toaster {
  position: absolute;
  width: 487px;
  bottom: 40px;
  // left: 50%;
  // transform: translateX(-50%);
}
