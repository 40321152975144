@import "../../../ui/media.scss";

.translations-upload {
  margin: 0;

  .doc-upload-button {
    margin: 10px 0 0 0;

    @include tablet {
      margin: 0;
      justify-content: flex-end;
    }
  }
}
