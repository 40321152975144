@import "../../ui/media.scss";

.menu {
  .menu-link {
    display: block;
    height: 40px;
    line-height: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: background-color 300ms ease;
    text-decoration: none;

    &.is-active {
      background-color: var(--link-alt);
    }

    &.is-disabled {
      color: var(--color-silver);

      .icon {
        fill: var(--color-silver);
      }
    }

    // &.has-error {
    //   color: var(--color-red-heavy);
    //   .icon {
    //     fill: var(--color-red-heavy);
    //   }
    // }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--link-alt);
    }

    .nav-link-warning {
      margin-left: 50px;

      .icon {
        fill: var(--color-red-heavy);
      }
    }
  }
}
