//////////// CHIP
.root {
  position: relative;
}

.chip {
  padding: 3px 10px;
  border-radius: 20px;
  font-weight: 500;
  background-color: var(--link-alt);
  color: var(--color-link);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: unset;
  gap: 8px;

  &:hover {
    color: var(--color-button-hovered);
  }

  .chipText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

.chipStale {
  width: 165px;
  background-color: var(--color-red-light);
  color: var(--color-red-heavy);

  &:hover {
    background-color: var(--color-delete-light);
    color: var(--color-delete-heavy);
  }
}

.chipDisabled {
  opacity: 0.5;
  background-color: var(--color-snow-heavy);
  color: var(--color-text-passive);
  cursor: default;

  &:hover {
    background-color: var(--color-snow-heavy);
    color: var(--color-text-passive);
  }
}

//////////// POPOVER

.popover {
  min-width: 375px;
  position: absolute;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.12);
}

.header {
  border-bottom: 1px solid var(--color-snow-heavy);
  padding: 10px 20px;

  &Disabled {
    color: var(--color-text-passive);
  }
}

.ul {
  padding: 8px 0;
}

.li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 20px;
  font-weight: 500;
  color: var(--color-text-passive);

  &:hover {
    background-color: var(--color-snow-light);
  }
}

.liSelected {
  font-weight: 600;
  fill: var(--color-link);
  color: var(--color-link);
}

.liDisabled {
  cursor: not-allowed;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: space-between;

  button:nth-child(2) {
    flex: 1;
  }
}

.errorMessage {
  margin-top: 20px;
  font-size: 0.9em;
  font-weight: 500;
  color: var(--color-red-heavy);
  display: flex;
  align-items: center;
  gap: 10px;
}

////////// TOOLTIP

.tooltip {
  width: 360px;
  min-width: max-content;
  position: absolute;
  z-index: 999;
  background-color: var(--color-yellow-light);
  border-radius: 10px;
  box-shadow: 0 12px 20px 6px rgb(104 112 118 / 0.12);

  text-align: center;

  font-size: 0.9em;
  padding: 5px 20px;
  border-radius: 50px;
}
