.case-complete {
  .cards {
    > div {
      width: 100%;
      display: flex;

      > div {
        flex: 1;
      }
    }
  }

  .divider {
    width: calc(100% - 40px);
    height: 1px;
    opacity: 0.5;
    background-color: var(--color-link);
    margin: 10px 10px;
  }

  .beneficial-owners {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
