@import "../../ui/media.scss";

.mcc-info-list {
  display: flex;
  flex-direction: column;

  .mcc-info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: 500;

    .beacon {
      margin: 0 6px 0 0;
    }

    div {
      display: flex;
      flex-direction: row;
    }

    .row {
      display: inline;
    }
  }
}
