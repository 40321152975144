@import "../../../ui/media.scss";

.button-pane {
  box-shadow: var(--color-button-shadow);
  border-radius: 5px;
  max-width: 100%;

  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  &.length-2,
  &.length-3,
  &.length-4,
  &.length-5 {
    button {
      @include tablet {
        min-width: 0;
        flex: 1 0 0%;
      }
    }
  }

  &.is-disabled {
    box-shadow: var(--color-button-shadow-transparent);

    button {
      color: var(--color-silver);
      border: 2px solid var(--color-silver);
    }
  }

  &.small {
    button {
      height: var(--height-interaction-small);

      @include tablet {
        height: var(--height-interaction-small-tablet);
      }
    }
  }

  button {
    display: block;
    justify-content: center;
    align-items: center;
    min-width: 0;
    height: var(--height-interaction);
    padding: 0 16px;
    transition: background-color 300ms ease, box-shadow 300ms ease,
      color 300ms ease;
    background-color: var(--color-white);
    color: var(--color-button-ghost);
    border: 2px solid var(--color-button-ghost);
    font-weight: 600;
    border-bottom: 0;
    width: 100%;

    &.active,
    &:hover,
    &:focus,
    &:active {
      background-color: var(--link-alt);
      background-color: var(--link);
      color: var(--color-white);
    }

    & > span {
      width: 100%;
      display: block;
    }

    @include tablet {
      border: 2px solid var(--color-button-ghost);
      border-right: 0;
      display: inline-flex;
      height: var(--height-interaction-tablet);
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 2px solid var(--color-button-ghost);

      @include tablet {
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      @include tablet {
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
      }
    }

    &:disabled,
    &[disabled] {
      color: var(--color-silver);
      border: 2px solid var(--color-silver);
      background-color: var(--color-white);
      cursor: not-allowed;
    }
  }
}
