.mcc-additional-info {
  display: flex;

  div {
    display: flex;
    align-items: center;
  }

  .beacon {
    margin: 0 6px 0 0;
  }

  .remove-button {
    position: relative;
    margin-right: 4px;

    &.--disabled {
      pointer-events: none;
      opacity: 0.5;

      .icon {
        fill: var(--color-text-passive);
      }
    }

    .icon {
      fill: var(--color-red-heavy);
    }
  }
}

.mcc-input {
  .input.input-text {
    margin: 0 0 5px 0;
  }

  .input-hint {
    display: none;
  }

  .input-messages {
    min-height: 0;
  }
}
