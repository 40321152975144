button.as-link {
  height: initial;
  background: none;
  box-shadow: none;
  text-align: center;
  display: inline;
  padding: 0;

  &:hover,
  &:active,
  &:focus {
    background: none;
    box-shadow: none;
    color: var(--color-link-hovered);
    text-decoration: underline;
  }
}

a,
.link,
.as-link {
  color: var(--color-link);
  font-weight: 500;
  text-decoration: none;
  vertical-align: baseline;

  &.block {
    display: block;
  }

  &.is-disabled {
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--color-link-hovered);
    text-decoration: underline;
  }

  .icon {
    width: 20px;
    fill: var(--color-link);
    vertical-align: middle;
    margin: -2px 5px 0 0;
  }

  &.as-button:not(&.ghost) {
    .icon {
      stroke: var(--color-white);
      fill: none;
    }
  }

  &.postfix {
    .icon {
      margin: -2px 0 0 4px;
    }
  }

  .icon-external {
    fill: none;
    stroke: var(--color-link);
    width: 16px;
    stroke-width: 2px;
  }

  &.light {
    color: var(--color-white);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: var(--color-white);
      text-decoration: underline;
    }

    .icon {
      fill: var(--color-white);
    }

    .icon-external {
      fill: none;
      stroke: var(--color-white);
      width: 16px;
      stroke-width: 2px;
    }
  }
}
