.country-selector {
  display: flex;

  .input-dropdown {
    flex: 1;
    margin-left: 12px;
    max-width: 200px;
    margin-left: auto;

    .input-label-wrapper {
      display: none;
    }

    --height-interaction-tablet: 32px;

    svg {
      top: 3px;
      right: 3px;
    }

    select {
      border: 1px solid var(--color-progress-border);
    }
  }

  ul {
    margin: 0;
  }

  li {
    display: inline-block;
    margin: 0 16px 0 0;
    opacity: 0.5;
    background-color: var(--color-white);
    box-shadow: 0 0 8px -3px var(--color-slate);
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    transition: opacity 0.2s ease-in-out;

    .flag {
      box-shadow: none;
      min-width: 40px;
      background-color: var(--color-bg);
    }

    &:not(.selected) {
      opacity: 0.5;
    }

    &.selected {
      opacity: 1;
    }

    &:last-child {
      margin: 0;
    }
  }

  .active-country-label {
    font-weight: 600;
    display: block;

    > span {
      padding: 0 8px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
