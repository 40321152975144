@import "../../../../ui/media.scss";

.dashboard-filter {
  max-width: $desktop-plus;
}

.filter-breadcrumbs-wrapper {
  display: none;

  @include tablet {
    display: flex;
    width: max-content;
    width: calc(100% - 20px);
  }

  @include desktop-plus {
    font-size: 1.5rem;
  }

  @include wide {
    font-size: 1.6rem;
  }
}

.filter-breadcrumbs {
  display: flex;
  font-size: 1.4rem;
  margin: 0 0 40px 0;
  flex: 1;
  min-width: 0;

  &.--alone {
    flex: unset;
    margin-right: 14px;
  }

  li {
    flex: 1;
    min-width: 0;
  }
}

.filter-breadcrumb {
  border-top: 2px solid var(--color-link);
  border-bottom: 2px solid var(--color-link);

  .link {
    display: block;
    padding: 0 0 0 30px;
    height: 36px;
    line-height: 36px;
    font-weight: 600;
    background-color: var(--color-bg);
    position: relative;
    text-decoration: none;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: var(--link-alt);

      .filter-arrow {
        background-color: var(--link-alt);
      }
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  &:first-child {
    // .link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 2px solid var(--color-link);
    // }

    .link {
      padding: 0 0 0 10px;
    }
  }

  &.all {
    width: 100%;
    border-right: 2px solid var(--color-link);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;

    .link {
      padding-right: 10px;
    }
  }

  &.is-active {
    background-color: var(--color-link);
    color: var(--color-white);

    .link {
      color: var(--color-white);
      background-color: var(--color-link);

      .filter-arrow {
        background-color: var(--color-link);
      }
    }
  }

  .filter-name-and-count {
    position: relative;
    z-index: 5;
    width: 100%;
    @include truncate;
    display: block;
  }

  .filter-arrow {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    background-color: var(--color-bg);
    border-top: 2px solid var(--color-link);
    border-right: 2px solid var(--color-link);
    height: 28.28px;
    width: 28.28px;
    transform-origin: center;
    transform: translate(14px, 4px) rotate(45deg);
    transition: background-color 0.1s ease-in-out;
  }

  .filter-count {
    margin: 0 0 0 5px;
    font-weight: 700;
  }

  &.is-active {
    .filter-arrow {
      background-color: var(--color-link);
    }
  }
}
