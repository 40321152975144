@import "../../ui/media.scss";

.vertical-menu {
  display: flex;
  flex-direction: column;

  ul {
    .logout-list-item {
      border-top: 1px solid var(--color-progress-border);
      padding: 20px 0 0 0;
      margin: 30px 0 0 0;
    }

    .language {
      order: 1;
      flex: 0 0 100%;

      button {
        font-size: 3.4rem;
        @include tablet {
          font-size: 4rem;
        }
      }

      ul {
        margin: 0 0 0 0;
      }
      li {
        display: inline-block;
        margin: -4px 14px 0 0;
      }
      @include tablet {
        order: 3;
        flex: 0 0 280px;
      }
    }
  }

  .logout-list-item {
    .button span {
      @include truncate;
    }
  }

  .bottom-content {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .language-select {
    span {
      margin-left: 50px;
    }
  }

  .support-box {
    margin-left: -12px;
    margin-right: -12px;
  }
}
