$width: 20px;
$height: $width;

$largeWidth: 24px;
$largeHeight: $largeWidth;

.owner-share {
  position: relative;
  width: $width;
  height: $height;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;

  &.large {
    width: $largeWidth;
    height: $largeHeight;
    top: -3px;
  }

  .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    border-radius: #{$width * 0.5};
    pointer-events: none;
  }

  .owner-share-bg {
    fill: var(--color-white);
    fill: transparent;
  }

  .owner-share-circle {
    fill: none;
    stroke: var(--color-link);
    stroke-width: 8px;
  }

  .owner-share-fg {
    fill: var(--color-link);
  }

  .risk-indicator-line {
    stroke: var(--color-black);
    stroke-width: 1px;
    stroke-opacity: 0;
  }
}
