@import "../../../../ui/media.scss";

.select-bo-checkbox {
  height: 20px;
  width: 20px;
  margin-right: 12px;

  border-radius: 5px;
  border: 1px solid var(--color-silver);
  color: var(--color-white);
  fill: var(--color-white);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &--checked {
    background-color: var(--color-link);
    border-color: var(--color-link);
  }

  transition: background-color 150ms ease, border-color 150ms ease;
}

.beneficial-owner-row {
  padding-bottom: 10px;
  width: 100%;
  min-width: 0;
  display: flex;
  align-items: center;

  &-button {
    flex: 1;
    min-width: 0;
    text-align: left;
    color: var(--color-text);
    width: 100%;
  }

  &-action {
    margin-left: 12px;
    flex-shrink: 0;
  }

  &-left-action {
    margin-right: 12px;
    flex-shrink: 0;
  }

  &:last-of-type() {
    padding-bottom: 0;
  }

  .positive {
    border: 0;
    box-shadow: none;
  }

  &:active,
  &:focus,
  &:hover {
    .positive {
      background-color: var(--link-alt);
      box-shadow: var(--color-button-shadow-transparent);
    }
  }

  &.is-disabled {
    pointer-events: none;
  }
}
