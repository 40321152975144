@import "../../ui/media.scss";

.range-input {
  .input {
    margin-bottom: 0 !important;

    .input-label-wrapper {
      display: none;
    }
  }

  .input-wrapper {
    display: flex;
    width: 100%;

    .separator {
      display: inline-block;
      &::after {
        content: "-";
        display: flex;
        margin-right: 20px;
        margin-left: 20px;
        padding-top: 4px;
      }
    }

    .input {
      flex: 1;
    }
  }

  .input-messages {
    line-height: 1.3;
    position: relative;
    font-size: 1.5rem;
    font-style: italic;
    color: var(--color-text-passive);
    min-height: 20px;

    @include tablet {
      font-size: 1.7rem;
      min-height: 22px;
    }
  }

  &.has-error {
    .input-messages,
    .input-label {
      color: var(--color-text-error);
    }
  }
}
