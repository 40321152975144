@import "../../../ui/media.scss";

.dashboard {
  section {
    padding: 0;
  }

  .dashboard-search {
    padding: 0 16px 8px 16px;
    max-width: $desktop;

    @include landscape {
      padding: 0 25px 8px 25px;
    }

    @include tablet {
      padding: 0 25px 2px 25px;
    }

    @include desktop {
      padding: 0 25px 2px 50px;
    }
  }

  .filter-view {
    padding: 20px 16px;

    @include landscape {
      padding: 25px;
    }
  }
}
