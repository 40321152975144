@import "../../ui/media.scss";

.company-page {
  .input {
    margin: 0 0 14px 0;
    display: block;
  }

  .address-row {
    margin-top: 14px;

    @include tablet {
      margin-top: 0;
    }
  }

  .company-wrapper {
    overflow: hidden;

    .tag {
      display: flex;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--color-link);
      font-size: 0.9em;
      gap: 10px;
    }
    .flag {
      border-radius: 4px;
    }
  }

  .filter-table {
    width: 100%;
    max-width: $desktop-plus;
  }

  .case-list-wrapper {
    overflow: auto;
    width: 100%;
    padding: 0;
    padding-bottom: 20px;
  }

  .edit-button-wrapper-column {
    margin: 20px 0 40px 0;
  }

  .edit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      width: 100%;
    }
  }

  .edit-button-warning-text {
    color: var(--color-red-heavy);
    font-style: italic;
    font-size: 0.9em;
  }

  .company-page-document-search {
    .input {
      margin: 0;
    }
    input {
      width: auto;
    }
    margin-bottom: 20px;
  }
}
