@import "../../ui/media.scss";

.onboarding-page {
  padding-top: 20px;

  @include tablet {
    padding-top: 40px;
  }

  article {
    max-width: $desktop-plus;
  }

  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    margin-bottom: 20px;

    h1 {
      width: 100%;
      margin: 20px 0 0px;
    }

    @include tablet {
      display: flex;
      justify-content: space-between;

      h1 {
        width: auto;
        margin: 0;
      }
    }

    .new-language-select {
      margin-left: auto;
    }
  }
}
