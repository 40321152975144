@import "../../../ui/media.scss";

.case-header,
.case {
  .case-cells {
    //display: inline-flex;
    width: min-content;
    padding: 0 16px 0 0;

    @include landscape {
      padding: 0 25px 0 0;
    }

    @include tablet {
      padding: 0;
      width: 100%;
      max-width: $desktop-plus;
    }

    .my-case {
      background-color: #ccffddd1;
    }
  }

  &.highlighted {
    .sales-user {
      font-weight: bold;
    }
  }

  .flag {
    margin-top: -2px;
    margin-right: 8px;
  }

  .created,
  .status,
  .sales-user,
  .company-name,
  .company-id,
  .stale,
  .documents,
  .updated {
    border-top: 1px solid var(--color-progress-border);
    background-color: var(--color-bg);
    flex: 0 0 120px;
    width: 120px;

    @include tablet {
      flex: 0 0 calc(16.66% - 8px);
      width: calc(16.66% - 8px);
      // flex: 0 0 calc(19% - 8px);
      // width: calc(19% - 8px);
    }

    & > div {
      @include truncate;
      width: 100%;
      padding: 5px 10px;
    }
  }

  .case-menu {
    flex: 0 0 48px;
    min-width: 48px;
    text-align: center;
    border-top: 1px solid var(--color-progress-border);
    background-color: var(--color-bg);
    padding: 4px 0 0 0;
  }

  .case-cell {
    &:first-child {
      border-left: 1px solid var(--color-progress-border);
    }

    &:last-child {
      border-right: 1px solid var(--color-progress-border);
    }

    &.status {
      position: relative;
      z-index: 2;
    }
  }

  &:last-child {
    .case-cell {
      border-bottom: 1px solid var(--color-progress-border);

      &:first-child {
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.case-header {
  .case-cell {
    border-top: 1px solid var(--color-progress-border);
    text-align: left;
    &:first-child {
      border-left: 1px solid var(--color-progress-border);
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-right: 1px solid var(--color-progress-border);
      border-top-right-radius: 5px;
    }
  }

  .created,
  .updated {
    text-align: right;
  }

  button {
    color: var(--color-text-passive);
    position: relative;
    font-weight: 600;
    font-size: unset;

    &:hover {
      color: var(--color-link);
    }
  }

  .ASC:after,
  .DESC:after {
    position: absolute;
    content: " ";
    border: 6px solid transparent;
    margin: 0 0 0 8px;
    left: -24px;
    transition: all 0.5s ease;
  }

  .DESC:after {
    top: 7px;
    border-top-color: var(--color-link);
  }

  .ASC:after {
    top: 1px;
    border-bottom-color: var(--color-link);
  }
}

.case {
  .created,
  .status,
  .phone,
  .sales-user,
  .company-name,
  .company-id,
  .customer-name,
  .stale,
  .documents,
  .updated {
    font-size: 1.4rem;

    @include desktop {
      font-size: 1.6rem;
    }
  }

  .company-name {
    max-width: 350px;
  }

  .created,
  .updated {
    text-align: right;
  }
}

.case-header {
  color: var(--color-text-passive);
  // border-top-right-radius: 5px;
  // border-top-left-radius: 5px;
  // overflow: hidden;

  .created,
  .status,
  .phone,
  .sales-user,
  .company-name,
  .company-id,
  .customer-name,
  .stale,
  .documents,
  .updated {
    background-color: var(--color-table-header);
    font-weight: 600;
    font-size: 1.4rem;

    @include desktop {
      font-size: 1.6rem;
    }

    & > div {
      padding: 14px 10px;
    }
  }

  .case-menu {
    background-color: var(--color-table-header);

    & > button {
      // padding: 14px 10px;
      visibility: hidden;
      pointer-events: none;
    }
  }
}

.case-cell-menu-button {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--color-progress-bar-bg);
  border-radius: 50%;
  z-index: 11;
}

.case-cell-menu {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-bg);
  z-index: 210;
  display: none;
  min-width: 200px;
  border-radius: 5px;
  box-shadow: var(--color-submenu-shadow);
  text-align: left;
  padding: 3px 0;

  .link {
    display: block;
    padding: 2px 10px;
  }

  .delete {
    &.link {
      background-color: var(--color-delete-bg);
      color: var(--color-delete-text);
    }

    .icon {
      fill: var(--color-delete-text);
    }
  }

  & > .action {
    position: absolute;
    top: 5px;
    right: 11px;
    background-color: var(--color-bg);

    @include tablet {
      top: 4px;
      right: 9px;
    }
  }
}

.case-cell {
  position: relative;

  &.is-open {
    .case-cell-menu {
      display: block;
    }
  }
}

.case-status-cell {
  position: absolute;
  left: 6px;
  top: 4px;
  max-width: calc(100% - 30px);

  & > span:first-child {
    opacity: 0;
    height: 0;
  }

  .case-cell-notification {
    position: absolute;
    left: 100%;
    top: 0;
    padding: 2px 3px;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.05rem;
    border-radius: 3px;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 8px;

    @include tablet {
      font-size: 10px;
    }
  }

  .case-action-needed {
    background-color: var(--color-notification-error-bg);
    color: var(--color-notification-error-text);
  }

  .case-done {
    background-color: var(--color-notification-done-bg);
    color: var(--color-notification-done-text);
  }
}
