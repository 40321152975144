@import "../../../ui/media.scss";

.root {
  max-width: $tablet;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 12px;

  --outer-circle-width: 26px;
  --inner-circle-width: 18px;
  --step-title-width: 50px;

  --circle-container-width: calc(
    var(--step-title-width) / 2 + var(--outer-circle-width) / 2
  );

  @include tablet {
    --outer-circle-width: 36px;
    --inner-circle-width: 22px;
    --step-title-width: 150px;
  }
}

.indicator {
  display: flex;
  flex: 1;
  align-items: center;

  .circleContainer {
    &:first-of-type {
      width: var(--circle-container-width);

      .stepCircle {
        margin-left: auto;
      }
    }

    &:last-of-type {
      width: var(--circle-container-width);
    }
  }

  .stepCircle {
    display: flex;
    justify-content: center;
    align-items: center;

    height: var(--outer-circle-width);
    width: var(--outer-circle-width);
    border-radius: 50%;
    background-color: var(--color-snow-heavy);
    position: relative;

    .circleInner {
      height: var(--inner-circle-width);
      width: var(--inner-circle-width);
      border-radius: 50%;
      background-color: var(--color-bg);
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 500;
    }
  }

  .stepCircleActive {
    background-color: var(--color-link);

    span {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--color-link);
      border-radius: 50%;
      opacity: 0.8;
      animation: pulseAnimate 1.75s ease-out infinite;
    }
  }

  .stepCircleCompleted {
    background-color: var(--color-link);
    fill: #ffffff;

    .circleInner {
      background-color: var(--color-link);
    }
  }

  .stepLine {
    flex: 1;
    height: 3px;
    background-color: var(--color-snow-heavy);
    @include tablet {
      height: 5px;
    }
  }

  .stepLineActive {
    background-color: var(--color-link);
  }
}

.stepTitles {
  display: flex;
  flex: 1;
  justify-content: space-between;

  gap: 12px;

  .stepTitle {
    width: var(--step-title-width);
    font-weight: 500;
    color: var(--color-text-passive);
    text-align: center;
    line-height: normal;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      white-space: normal;
      overflow: visible;
      text-overflow: visible;
    }
  }

  .stepTitleActive {
    color: var(--color-link);
    font-weight: 600;
  }
}

.message {
  color: var(--color-text-passive);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
  font-weight: 500;

  svg {
    width: 24px;
    height: 24px;
  }
}

@keyframes pulseAnimate {
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
